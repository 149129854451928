var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: {
            size: "mini",
            model: _vm.config.baseData,
            "label-position": "top"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "会员本人可以报的单型" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.config.baseData.memberBdType,
                    callback: function($$v) {
                      _vm.$set(_vm.config.baseData, "memberBdType", $$v)
                    },
                    expression: "config.baseData.memberBdType"
                  }
                },
                [
                  _c("el-checkbox", { attrs: { label: 2, name: "type" } }, [
                    _vm._v("复销单")
                  ]),
                  _c("el-checkbox", { attrs: { label: 3, name: "type" } }, [
                    _vm._v("升级单")
                  ]),
                  _c("el-checkbox", { attrs: { label: 4, name: "type" } }, [
                    _vm._v("激活单")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "服务中心可以报的单型" } },
            [
              _c(
                "el-checkbox-group",
                {
                  model: {
                    value: _vm.config.baseData.centerBdType,
                    callback: function($$v) {
                      _vm.$set(_vm.config.baseData, "centerBdType", $$v)
                    },
                    expression: "config.baseData.centerBdType"
                  }
                },
                [
                  _c("el-checkbox", { attrs: { label: 1, name: "type" } }, [
                    _vm._v("注册单")
                  ]),
                  _c("el-checkbox", { attrs: { label: 2, name: "type" } }, [
                    _vm._v("复销单")
                  ]),
                  _c("el-checkbox", { attrs: { label: 3, name: "type" } }, [
                    _vm._v("升级单")
                  ]),
                  _c("el-checkbox", { attrs: { label: 4, name: "type" } }, [
                    _vm._v("激活单")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label:
                  "是否只有服务中心具备汇款充值功能，其他会员无法进行汇款充值"
              }
            },
            [
              _c(
                "el-radio-group",
                {
                  on: { change: _vm.centerBdOnlyChange },
                  model: {
                    value: _vm.config.baseData.centerBdOnly,
                    callback: function($$v) {
                      _vm.$set(_vm.config.baseData, "centerBdOnly", $$v)
                    },
                    expression: "config.baseData.centerBdOnly"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            {
              attrs: {
                label:
                  "服务中心是否可以给推荐网下所属服务中心不是本服务中心的会员报单"
              }
            },
            [
              _c(
                "el-radio-group",
                {
                  attrs: { disabled: _vm.config.baseData.centerBdOnly == 0 },
                  model: {
                    value: _vm.config.baseData.canCrossBd,
                    callback: function($$v) {
                      _vm.$set(_vm.config.baseData, "canCrossBd", $$v)
                    },
                    expression: "config.baseData.canCrossBd"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "提现时扣除提现手续费" } },
            [
              _c(
                "el-row",
                [
                  _c("el-input-number", {
                    attrs: { min: 0, max: 100 },
                    model: {
                      value: _vm.config.baseData.takeTax,
                      callback: function($$v) {
                        _vm.$set(_vm.config.baseData, "takeTax", $$v)
                      },
                      expression: "config.baseData.takeTax"
                    }
                  }),
                  _c("span", { staticStyle: { "margin-left": "10px" } }, [
                    _vm._v("%")
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否有追加注册产品" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.config.baseData.additionalProduct,
                    callback: function($$v) {
                      _vm.$set(_vm.config.baseData, "additionalProduct", $$v)
                    },
                    expression: "config.baseData.additionalProduct"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "服务中心是否可以撤单" } },
            [
              _c(
                "el-radio-group",
                {
                  model: {
                    value: _vm.config.baseData.storeCancelOrder,
                    callback: function($$v) {
                      _vm.$set(_vm.config.baseData, "storeCancelOrder", $$v)
                    },
                    expression: "config.baseData.storeCancelOrder"
                  }
                },
                [
                  _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                  _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }