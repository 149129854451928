import "core-js/modules/es.array.slice";
import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from '@/components/rw/tempTable/index';
import { expectNumList, userBatchImport, userImport } from '../../../../api/apis';
export default {
  components: {
    tempTable: tempTable
  },
  data: function data() {
    var _this = this;

    return {
      batchNo: '',
      batchList: [],
      dialogVisible: false,
      tableData: [],
      tableModel_1001: {
        'border': true,
        'del': false,
        'headerButton': [],
        'id': 'id_1001',
        'isGroup': false,
        'isTree': false,
        'rowButton': [],
        'showPagination': true,
        'showSelection': true,
        'showSummary': false,
        'stripe': false,
        'tableAutocompleteProps': {},
        'tableCascaderProps': {},
        'tableFileProps': {},
        'tableModel': [{
          'add': 1,
          'desc': '',
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '手机号码',
          'required': 1,
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': null,
          'prop': 'phone'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '会员姓名',
          'required': 1,
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': null,
          'prop': 'real_name'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'groupId': '',
          'label': '会员编号',
          'required': 1,
          'rowAddField': '',
          'rules': [],
          'show': 1,
          'type': 'text',
          'width': null,
          'prop': 'number'
        }],
        'tableName': 't_user',
        'tableRadioProps': {},
        'tableSelectProps': {},
        'tableSwitchProps': {},
        'title': '会员',
        'type': 'tableTemplate'
      },
      remoteFunc: {}
    };
  },
  mounted: function mounted() {
    var _this2 = this;

    this.tableModel_1001.headerButton = [];
    this.tableModel_1001.rowButton = [];
    this.tableModel_1001.showSelection = false;
    expectNumList({}).then(function _callee(res) {
      return regeneratorRuntime.async(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _this2.batchList = res;
              _this2.batchNo = res[0].expect_num;
              console.log('res', res);

            case 3:
            case "end":
              return _context.stop();
          }
        }
      });
    });
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var data = this.tableData.slice((params.page - 1) * params.limit, params.page * params.limit);
      console.log('cccc', data);
      resolve({
        total: this.tableData.length,
        data: data
      });
    },
    show: function show(finish) {
      this.dialogVisible = true;
      this.finish = finish;
      this.tableData = [];
      this.$refs.ref_1001.reloadData();
    },
    hide: function hide() {
      this.dialogVisible = false;
      this.finish();
    },
    clickSubmit: function clickSubmit() {
      var _this3 = this;

      if (this.batchNo === '') {
        this.$message({
          message: '请选择活动批次号',
          type: 'error'
        });
        return;
      }

      this.$g.fun.startLoading('导入中，请稍后...');
      userImport({
        data: this.tableData,
        expect_num: this.batchNo
      }).then(function _callee2(res) {
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                _this3.hide();

                _this3.$message({
                  message: '导入成功',
                  type: 'success'
                });

                _this3.$g.fun.endLoading();

              case 3:
              case "end":
                return _context2.stop();
            }
          }
        });
      }).catch(function () {
        _this3.$g.fun.endLoading();
      });
    },
    clickImport: function clickImport() {
      this.$refs.import.value = null; //解决change事件在同一个文件下不触发的问题

      this.$refs.import.click();
    },
    clickDownload: function clickDownload() {
      var columns = [{
        label: '会员姓名',
        prop: 'real_name'
      }, {
        label: '会员编号',
        prop: 'number'
      }, {
        label: '手机号码',
        prop: 'phone'
      }];
      this.$export.excel({
        columns: columns,
        data: [{
          'real_name': 'xxxx',
          'number': '888888888',
          'phone': '138xxxxxxxx'
        }],
        title: '导入会员模板'
      });
    },
    onExcelImport: function onExcelImport(event) {
      var _this4 = this;

      var file = event.target.files[0];
      this.$import.xlsx(file).then(function (_ref) {
        var header = _ref.header,
            results = _ref.results;
        var data = [];

        for (var i in results) {
          var item = results[i];
          data.push({
            real_name: item['会员姓名'],
            phone: item['手机号码'],
            number: item['会员编号']
          });
        }

        console.log('result', data);
        _this4.tableData = data;

        _this4.$refs.ref_1001.reloadData();
      }); // 阻止默认的上传

      return false;
    }
  }
};