var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.form, size: "mini", "label-position": "top" }
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      "会员本人报的升级单，是否用于给所属服务中心计算税务补贴"
                  }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.bonusStoreOrder2,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "bonusStoreOrder2", $$v)
                        },
                        expression: "form.bonusStoreOrder2"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      "会员本人报的复销单，是否用于给所属服务中心计算税务补贴"
                  }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.bonusStoreOrder3,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "bonusStoreOrder3", $$v)
                        },
                        expression: "form.bonusStoreOrder3"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      "会员本人报的激活单，是否用于给所属服务中心（该服务中心对应会员必须在该系统处于激活状态）"
                  }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.bonusStoreOrder4,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "bonusStoreOrder4", $$v)
                        },
                        expression: "form.bonusStoreOrder4"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      "服务中心给会员报单时，税务补贴归报单的服务中心，还是归会员所属服务中心"
                  }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.form.bonusStoreOwn,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "bonusStoreOwn", $$v)
                        },
                        expression: "form.bonusStoreOwn"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("归报单者")
                      ]),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("归所属者")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        { staticStyle: { "margin-top": "10px" } },
        [
          _c(
            "el-table",
            { staticStyle: { width: "100%" }, attrs: { data: _vm.data } },
            [
              _c("el-table-column", {
                attrs: { label: "服务中心唯一标识", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-row",
                          [
                            _c("el-input", {
                              staticStyle: { width: "80%" },
                              attrs: { size: "mini", placeholder: "唯一ID" },
                              model: {
                                value: scope.row.grade,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "grade", $$v)
                                },
                                expression: "scope.row.grade"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "服务中心级别", width: "220" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-row",
                          [
                            _c("el-input", {
                              staticStyle: { width: "80%" },
                              attrs: { size: "mini", placeholder: "级别名称" },
                              model: {
                                value: scope.row.name,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "name", $$v)
                                },
                                expression: "scope.row.name"
                              }
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "提成比例", width: "150" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-row",
                          [
                            _c("el-input-number", {
                              staticStyle: { width: "80%" },
                              attrs: { size: "mini", min: 0, max: 100 },
                              model: {
                                value: scope.row.rate,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "rate", $$v)
                                },
                                expression: "scope.row.rate"
                              }
                            }),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "10px" } },
                              [_vm._v("%")]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.clickDelete(scope.$index, scope.row)
                              }
                            }
                          },
                          [_vm._v("删除 ")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "el-button",
            {
              staticStyle: { width: "100px", "margin-top": "20px" },
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.clickAddCond }
            },
            [_vm._v("添加 ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }