var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.conditions, function(item, index) {
        return _c(
          "el-card",
          { staticStyle: { "margin-bottom": "10px" } },
          [
            _c(
              "div",
              { attrs: { slot: "header" }, slot: "header" },
              [
                _c("span", [_vm._v(_vm._s("条件" + (index + 1)))]),
                _c(
                  "el-button",
                  {
                    staticStyle: { float: "right" },
                    attrs: { size: "mini", type: "danger" },
                    on: {
                      click: function($event) {
                        return _vm.clickDelCond(index)
                      }
                    }
                  },
                  [_vm._v("删除")]
                )
              ],
              1
            ),
            _vm._l(item, function(subItem, subIndex) {
              return _c(
                "el-row",
                [
                  _c(
                    "el-card",
                    { staticStyle: { "margin-top": "5px" } },
                    [
                      _c(
                        "el-row",
                        [
                          _c("span", [
                            _vm._v(_vm._s("子条件" + (subIndex + 1)))
                          ]),
                          _c(
                            "el-select",
                            {
                              staticStyle: { "margin-left": "20px" },
                              attrs: {
                                size: "mini",
                                filterable: "",
                                placeholder: "请选择条件"
                              },
                              on: {
                                change: function(value) {
                                  _vm.onCondChange(index, subIndex, value)
                                }
                              },
                              model: {
                                value: subItem.id,
                                callback: function($$v) {
                                  _vm.$set(subItem, "id", $$v)
                                },
                                expression: "subItem.id"
                              }
                            },
                            _vm._l(_vm.conditionList, function(cond) {
                              return _c("el-option", {
                                key: cond.id,
                                attrs: { label: cond.name, value: cond.id }
                              })
                            }),
                            1
                          ),
                          subItem.type != null
                            ? _c(
                                "el-select",
                                {
                                  staticStyle: { "margin-left": "20px" },
                                  attrs: { size: "mini" },
                                  model: {
                                    value: subItem.type,
                                    callback: function($$v) {
                                      _vm.$set(subItem, "type", $$v)
                                    },
                                    expression: "subItem.type"
                                  }
                                },
                                [
                                  _c("el-option", {
                                    attrs: { label: "累计", value: 0 }
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "周新增", value: 1 }
                                  }),
                                  _c("el-option", {
                                    attrs: { label: "月新增", value: 2 }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          subItem.myself != null
                            ? _c(
                                "el-checkbox",
                                {
                                  staticStyle: { "margin-left": "20px" },
                                  model: {
                                    value: subItem.myself,
                                    callback: function($$v) {
                                      _vm.$set(subItem, "myself", $$v)
                                    },
                                    expression: "subItem.myself"
                                  }
                                },
                                [_vm._v("包含本人业绩 ")]
                              )
                            : _vm._e(),
                          _c(
                            "el-button",
                            {
                              staticStyle: {
                                float: "right",
                                padding: "3px 0",
                                "margin-left": "20px"
                              },
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.clickDelSubCond(item, subIndex)
                                }
                              }
                            },
                            [_vm._v("删除")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticStyle: { float: "right", padding: "3px 0" },
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.clickResetParams(index, subIndex)
                                }
                              }
                            },
                            [_vm._v("重置参数")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-divider",
                        { attrs: { "content-position": "left" } },
                        [_vm._v("参数设置")]
                      ),
                      _vm._l(subItem.params, function(param) {
                        return _c(
                          "el-row",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-direction": "row",
                              "align-items": "center",
                              "margin-top": "10px"
                            }
                          },
                          [
                            _c("span", { staticStyle: { width: "100px" } }, [
                              _vm._v(_vm._s(param.name))
                            ]),
                            _c(
                              "el-select",
                              {
                                staticStyle: {
                                  "margin-left": "20px",
                                  width: "70px"
                                },
                                attrs: { size: "mini" },
                                model: {
                                  value: param.op,
                                  callback: function($$v) {
                                    _vm.$set(param, "op", $$v)
                                  },
                                  expression: "param.op"
                                }
                              },
                              [
                                _c("el-option", {
                                  attrs: { label: ">=", value: ">=" }
                                }),
                                _c("el-option", {
                                  attrs: { label: "=", value: "=" }
                                }),
                                _c("el-option", {
                                  attrs: { label: "<=", value: "<=" }
                                }),
                                _c("el-option", {
                                  attrs: { label: ">", value: ">" }
                                }),
                                _c("el-option", {
                                  attrs: { label: "<", value: "<" }
                                })
                              ],
                              1
                            ),
                            param.grade != null
                              ? _c(
                                  "el-select",
                                  {
                                    staticStyle: { "margin-left": "20px" },
                                    attrs: {
                                      size: "mini",
                                      filterable: "",
                                      placeholder: "请选择等级"
                                    },
                                    model: {
                                      value: param.value,
                                      callback: function($$v) {
                                        _vm.$set(param, "value", $$v)
                                      },
                                      expression: "param.value"
                                    }
                                  },
                                  _vm._l(_vm.gradeList, function(grade) {
                                    return _c("el-option", {
                                      key: grade.tag,
                                      attrs: {
                                        label: grade.name,
                                        value: grade.tag
                                      }
                                    })
                                  }),
                                  1
                                )
                              : param.level != null
                              ? _c(
                                  "el-select",
                                  {
                                    staticStyle: { "margin-left": "20px" },
                                    attrs: {
                                      size: "mini",
                                      filterable: "",
                                      placeholder: "请选择资格"
                                    },
                                    model: {
                                      value: param.value,
                                      callback: function($$v) {
                                        _vm.$set(param, "value", $$v)
                                      },
                                      expression: "param.value"
                                    }
                                  },
                                  _vm._l(_vm.levelList, function(level) {
                                    return _c("el-option", {
                                      key: level.tag,
                                      attrs: {
                                        label: level.name,
                                        value: level.tag
                                      }
                                    })
                                  }),
                                  1
                                )
                              : _c("el-input", {
                                  staticStyle: {
                                    width: "130px",
                                    "margin-left": "20px"
                                  },
                                  attrs: {
                                    size: "mini",
                                    placeholder: "请输入参数值"
                                  },
                                  model: {
                                    value: param.value,
                                    callback: function($$v) {
                                      _vm.$set(param, "value", $$v)
                                    },
                                    expression: "param.value"
                                  }
                                }),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "20px" } },
                              [_vm._v(_vm._s(param.unit))]
                            )
                          ],
                          1
                        )
                      })
                    ],
                    2
                  )
                ],
                1
              )
            }),
            _c(
              "el-row",
              { staticStyle: { "margin-top": "10px" } },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "mini", type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.clickAddSubCond(index)
                      }
                    }
                  },
                  [_vm._v("添加或条件")]
                )
              ],
              1
            )
          ],
          2
        )
      }),
      _c(
        "el-row",
        [
          _c(
            "el-button",
            {
              staticStyle: { width: "100px", "margin-top": "20px" },
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.clickAddCond }
            },
            [_vm._v("添加条件")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }