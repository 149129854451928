import "core-js/modules/es.array.splice";
import "core-js/modules/es.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    conditions: {
      type: Object
    },
    storeData: {
      type: Array
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      data: [],
      form: {
        bonusStoreOrder2: 0,
        bonusStoreOrder3: 0,
        bonusStoreOrder4: 0,
        bonusStoreOwn: 0
      }
    };
  },
  mounted: function mounted() {
    if (this.conditions) {
      this.data = this.conditions.data;

      for (var key in this.form) {
        this.form[key] = this.conditions[key];
      }

      console.log('this.data', this.data, this.storeData);

      if (this.data == null || this.data.length == 0) {
        this.data = [];

        for (var i in this.storeData) {
          var item = this.storeData[i];
          this.data.push({
            store: item.tag,
            name: item.name,
            rate: ''
          });
        }
      }
    } else {
      for (var _i in this.storeData) {
        var _item = this.storeData[_i];
        this.data.push({
          store: _item.tag,
          name: _item.name,
          rate: ''
        });
      }
    }
  },
  methods: {
    clickDelete: function clickDelete(index, row) {
      var tmp = this.$tempModel.deepCopy(this.data);
      tmp.splice(index, 1);
      this.data = tmp;
    },
    clickAddCond: function clickAddCond() {
      this.data.push({
        store: '',
        name: '',
        rate: ''
      });
    },
    getParams: function getParams() {
      var params = this.form;
      params.data = this.data;
      return this.$tempModel.deepCopy(params);
    }
  }
};