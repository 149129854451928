import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
import { Message, MessageBox } from 'element-ui';
import util from '@/libs/util.js';
import router from '@/router';
import { expectNumList } from '@/api/apis';
import setting from '@/setting';
export default {
  namespaced: true,
  state: {
    expectNumList: [],
    curExpectNum: 1
  },
  actions: {
    loadExpectNumList: function loadExpectNumList(_ref) {
      var state = _ref.state,
          dispatch = _ref.dispatch;
      return new Promise(function _callee2(resolve) {
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                expectNumList({}).then(function _callee(res) {
                  return regeneratorRuntime.async(function _callee$(_context) {
                    while (1) {
                      switch (_context.prev = _context.next) {
                        case 0:
                          state.expectNumList = res;
                          state.curExpectNum = res[res.length - 1]['expect_num'];

                        case 2:
                        case "end":
                          return _context.stop();
                      }
                    }
                  });
                });
                resolve();

              case 2:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    }
  }
};