import "core-js/modules/es.array.splice";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    conditions: {
      type: Object
    },
    levelData: {
      type: Array
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      data: [],
      layerList: []
    };
  },
  mounted: function mounted() {
    var data = [];

    for (var i = 0; i < 25; i++) {
      data.push({
        id: i + 1,
        name: '拿' + (i + 1) + '代'
      });
    }

    this.layerList = data;

    if (this.conditions) {
      this.data = this.conditions.data;
    }
  },
  methods: {
    clickDelete: function clickDelete(index, row) {
      var tmp = this.$tempModel.deepCopy(this.data);
      tmp.splice(index, 1);
      this.data = tmp;
    },
    clickAddCond: function clickAddCond() {
      this.data.push({
        grade: '',
        layer: '',
        rate: ''
      });
    },
    clickAddAllCond: function clickAddAllCond() {
      for (var i = 0; i < this.levelData.length; i++) {
        var item = this.levelData[i];
        this.data.push({
          grade: item.tag,
          rate: 0
        });
      }
    },
    getParams: function getParams() {
      var params = {};
      params.data = this.data;
      return this.$tempModel.deepCopy(params);
    }
  }
};