import "core-js/modules/es.array.splice";
import "core-js/modules/es.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    gradeList: {
      type: Array
    },
    levelList: {
      type: Array
    },
    period: {
      type: Number
    }
  },
  data: function data() {
    return {
      conditions: [],
      conditionList: [{
        id: 0,
        name: '团队注册业绩',
        type: 0,
        myself: 0,
        params: [{
          name: '团队注册业绩',
          value: 0,
          op: '>=',
          unit: 'pv'
        }]
      }, {
        id: 1,
        name: '团队重购业绩',
        type: 0,
        myself: 0,
        params: [{
          name: '团队重购业绩',
          value: 0,
          op: '>=',
          unit: 'pv'
        }]
      }, {
        id: 2,
        name: '团队升级业绩',
        type: 0,
        myself: 0,
        params: [{
          name: '团队升级业绩',
          value: 0,
          op: '>=',
          unit: 'pv'
        }]
      }, {
        id: 3,
        name: '团队激活业绩',
        type: 0,
        myself: 0,
        params: [{
          name: '团队激活业绩',
          value: 0,
          op: '>=',
          unit: 'pv'
        }]
      }, {
        id: 4,
        name: '团队业绩',
        type: 0,
        myself: 0,
        params: [{
          name: '团队业绩',
          value: 0,
          op: '>=',
          unit: 'pv'
        }]
      }, {
        id: 5,
        name: '个人注册业绩',
        type: 0,
        params: [{
          name: '个人注册业绩',
          value: 0,
          op: '>=',
          unit: 'pv'
        }]
      }, {
        id: 6,
        name: '个人重购业绩',
        type: 0,
        params: [{
          name: '个人重购业绩',
          value: 0,
          op: '>=',
          unit: 'pv'
        }]
      }, {
        id: 7,
        name: '个人升级业绩',
        type: 0,
        params: [{
          name: '个人升级业绩',
          value: 0,
          op: '>=',
          unit: 'pv'
        }]
      }, {
        id: 8,
        name: '个人激活业绩',
        type: 0,
        params: [{
          name: '个人激活业绩',
          value: 0,
          op: '>=',
          unit: 'pv'
        }]
      }, {
        id: 9,
        name: '个人业绩',
        type: 0,
        params: [{
          name: '个人业绩',
          value: 0,
          op: '>=',
          unit: 'pv'
        }]
      }, {
        id: 10,
        name: '直推人数',
        type: 0,
        params: [{
          name: '直推人数',
          value: 0,
          op: '>=',
          unit: '人'
        }]
      }, {
        id: 11,
        name: '大市场注册业绩',
        type: 0,
        params: [{
          name: '大市场注册业绩',
          value: 0,
          op: '>=',
          unit: 'pv'
        }]
      }, {
        id: 12,
        name: '大市场重购业绩',
        type: 0,
        params: [{
          name: '大市场重购业绩',
          value: 0,
          op: '>=',
          unit: 'pv'
        }]
      }, {
        id: 13,
        name: '大市场升级业绩',
        type: 0,
        params: [{
          name: '大市场升级业绩',
          value: 0,
          op: '>=',
          unit: 'pv'
        }]
      }, {
        id: 14,
        name: '大市场激活业绩',
        type: 0,
        params: [{
          name: '大市场激活业绩',
          value: 0,
          op: '>=',
          unit: 'pv'
        }]
      }, {
        id: 15,
        name: '大市场总业绩',
        type: 0,
        params: [{
          name: '大市场总业绩',
          value: 0,
          op: '>=',
          unit: 'pv'
        }]
      }, {
        id: 16,
        name: '小市场注册业绩',
        type: 0,
        params: [{
          name: '小市场注册业绩',
          value: 0,
          op: '>=',
          unit: 'pv'
        }]
      }, {
        id: 17,
        name: '小市场重购业绩',
        type: 0,
        params: [{
          name: '小市场重购业绩',
          value: 0,
          op: '>=',
          unit: 'pv'
        }]
      }, {
        id: 18,
        name: '小市场升级业绩',
        type: 0,
        params: [{
          name: '小市场升级业绩',
          value: 0,
          op: '>=',
          unit: 'pv'
        }]
      }, {
        id: 19,
        name: '小市场激活业绩',
        type: 0,
        params: [{
          name: '小市场激活业绩',
          value: 0,
          op: '>=',
          unit: 'pv'
        }]
      }, {
        id: 20,
        name: '小市场总业绩',
        type: 0,
        params: [{
          name: '小市场总业绩',
          value: 0,
          op: '>=',
          unit: 'pv'
        }]
      }, {
        id: 21,
        name: '当前等级级别',
        params: [{
          name: '当前等级级别',
          value: 0,
          op: '>=',
          grade: 1,
          unit: '级别'
        }]
      }, {
        id: 22,
        name: '当前资格',
        params: [{
          name: '当前资格',
          value: 0,
          op: '>=',
          level: 1,
          unit: '资格'
        }]
      }, {
        id: 23,
        name: '团队人数',
        type: 0,
        params: [{
          name: '团队人数',
          value: 0,
          op: '>=',
          unit: '人'
        }]
      }, {
        id: 24,
        name: '团队订单数',
        type: 0,
        params: [{
          name: '团队订单数',
          value: 0,
          op: '>=',
          unit: '单'
        }]
      }, {
        id: 25,
        name: '直推资格人数',
        type: 0,
        params: [{
          name: '直推人数',
          value: 0,
          op: '>=',
          unit: '人'
        }, {
          name: '直推人资格',
          value: 0,
          op: '>=',
          level: 1,
          unit: '资格'
        }]
      }, {
        id: 26,
        name: '资格对应团队订单数',
        type: 0,
        params: [{
          name: '团队订单数',
          value: 0,
          op: '>=',
          unit: '单'
        }, {
          name: '审核人资格',
          value: 0,
          op: '>=',
          level: 1,
          unit: '资格'
        }]
      }, {
        id: 27,
        name: '直推市场等级人数',
        type: 0,
        params: [{
          name: '资格市场',
          value: 0,
          op: '>=',
          unit: '个'
        }, {
          name: '直推市场等级',
          value: 0,
          op: '>=',
          grade: 1,
          unit: '等级'
        }]
      }, {
        id: 28,
        name: '团队等级人数',
        type: 0,
        params: [{
          name: '需要人数',
          value: 0,
          op: '>=',
          unit: '人'
        }, {
          name: '需要等级',
          value: 0,
          op: '>=',
          grade: 1,
          unit: '等级'
        }]
      }, {
        id: 29,
        name: '小市场单数',
        type: 0,
        params: [{
          name: '单数',
          value: 0,
          op: '>=',
          unit: '单'
        }]
      }, {
        id: 30,
        name: '直推市场2种等级人数',
        type: 0,
        params: [{
          name: '资格市场1',
          value: 0,
          op: '>=',
          unit: '个'
        }, {
          name: '直推市场等级1',
          value: 0,
          op: '>=',
          grade: 1,
          unit: '等级'
        }, {
          name: '资格市场2',
          value: 0,
          op: '>=',
          unit: '个'
        }, {
          name: '直推市场等级2',
          value: 0,
          op: '>=',
          grade: 1,
          unit: '等级'
        }]
      }]
    };
  },
  mounted: function mounted() {},
  methods: {
    getCondData: function getCondData() {
      return this.conditions;
    },
    setCondData: function setCondData(value) {
      this.conditions = value;
    },
    condById: function condById(id) {
      for (var i in this.conditionList) {
        var item = this.conditionList[i];

        if (item.id == id) {
          return item;
        }
      }

      return [];
    },
    onCondChange: function onCondChange(index, subIndex, value) {
      var cond = this.condById(value);
      this.conditions[index].splice(subIndex, 1, this.$tempModel.deepCopy(cond));
    },
    clickResetParams: function clickResetParams(index, subIndex) {
      var data = this.$tempModel.deepCopy(this.conditions);
      var tmp = data[index][subIndex];

      for (var i in tmp.params) {
        tmp.params[i].value = '';
      }

      this.conditions = data;
    },
    clickAddCond: function clickAddCond() {
      var cond = this.$tempModel.deepCopy(this.conditionList[0]);

      if (cond.hasOwnProperty('type')) {
        cond.type = this.period;
      }

      this.conditions.push([cond]);
    },
    clickDelCond: function clickDelCond(index) {
      this.conditions.splice(index, 1);
    },
    clickAddSubCond: function clickAddSubCond(index) {
      var item = this.conditions[index];
      var cond = this.$tempModel.deepCopy(this.conditionList[0]);

      if (cond.hasOwnProperty('type')) {
        cond.type = this.period;
      }

      item.push(cond);
    },
    clickDelSubCond: function clickDelSubCond(item, index) {
      item.splice(index, 1);
    }
  }
};