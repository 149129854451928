var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-card",
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.config } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "是否允许跨级升" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.config.baseData.levelCanSkip,
                        callback: function($$v) {
                          _vm.$set(_vm.config.baseData, "levelCanSkip", $$v)
                        },
                        expression: "config.baseData.levelCanSkip"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "升级有效期，自注册之日起" } },
                [
                  _c(
                    "el-row",
                    [
                      _c("el-input", {
                        staticStyle: { width: "100px" },
                        attrs: { size: "mini" },
                        model: {
                          value: _vm.config.baseData.levelValidDay,
                          callback: function($$v) {
                            _vm.$set(_vm.config.baseData, "levelValidDay", $$v)
                          },
                          expression: "config.baseData.levelValidDay"
                        }
                      }),
                      _c("span", { staticStyle: { "margin-left": "10px" } }, [
                        _vm._v("天")
                      ])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "升级模式" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { size: "mini" },
                      model: {
                        value: _vm.config.baseData.levelModel,
                        callback: function($$v) {
                          _vm.$set(_vm.config.baseData, "levelModel", $$v)
                        },
                        expression: "config.baseData.levelModel"
                      }
                    },
                    [
                      _c("el-option", { attrs: { label: "不限", value: 0 } }),
                      _c("el-option", {
                        attrs: { label: "补差升级", value: 1 }
                      }),
                      _c("el-option", {
                        attrs: { label: "全额升级", value: 2 }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "自然晋级生效时间" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.config.baseData.levelValidType,
                        callback: function($$v) {
                          _vm.$set(_vm.config.baseData, "levelValidType", $$v)
                        },
                        expression: "config.baseData.levelValidType"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("当日")]),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("次日")])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c(
            "temp-table",
            _vm._b(
              {
                ref: "ref_1000",
                attrs: {
                  "remote-func": _vm.remoteFunc,
                  "req-table-data": _vm.reqTableData,
                  "req-add": _vm.reqAdd,
                  "req-del": _vm.reqDel,
                  "on-click-link": _vm.onClickLink,
                  "on-click-row-button": _vm.onClickRowButton,
                  "on-click-header-button": _vm.onClickHeaderButton,
                  "on-summary": _vm.onSummary,
                  "on-switch-change": _vm.onSwitchChange,
                  "on-row-button-show": _vm.onRowButtonShow
                }
              },
              "temp-table",
              _vm.tableModel_1000,
              false
            )
          )
        ],
        1
      ),
      _c("level-cond", {
        key: _vm.reloadTimer,
        ref: "cond",
        attrs: {
          "grade-list": _vm.config.gradeData,
          "level-list": _vm.config.levelData
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }