//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  data: function data() {
    return {
      dialogVisible: false,
      isAgree: false,
      form: {
        order_id: '',
        real_name: '',
        user_phone: '',
        refund_reason: '',
        pay_price: ''
      }
    };
  },
  created: function created() {},
  methods: {
    show: function show(agree, row, finish) {
      this.dialogVisible = true;
      this.isAgree = agree;
      this.finish = finish;
      this.form = this.$tempModel.deepCopy(row);
      this.form.refund_reason = "";
    },
    clear: function clear() {
      this.dialogVisible = false;
      this.finish = null;
    },
    clickCancel: function clickCancel() {
      this.clear();
    },
    clickSubmit: function clickSubmit() {
      if (this.finish) {
        console.log('row', this.form);
        this.finish(this.form);
      }

      this.clear();
    }
  }
};