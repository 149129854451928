//
//
//
//
//
//
import util from '@/libs/util';
export default {
  name: 'app',
  data: function data() {
    return {
      isRouterAlive: true
    };
  },
  watch: {
    '$i18n.locale': 'i18nHandle'
  },
  created: function created() {
    this.i18nHandle(this.$i18n.locale);
  },
  mounted: function mounted() {
    this.$tempModel.reload = this.reload;
  },
  methods: {
    i18nHandle: function i18nHandle(val, oldVal) {
      util.cookies.set('lang', val);
      document.querySelector('html').setAttribute('lang', val);
    },
    reload: function reload() {
      var _this = this;

      this.isRouterAlive = false;
      this.$nextTick(function () {
        _this.isRouterAlive = true;
      });
    }
  }
};