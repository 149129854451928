import "core-js/modules/es.array.splice";
import "core-js/modules/es.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import conditionCreator from '../conditionCreator';
export default {
  components: {
    conditionCreator: conditionCreator
  },
  props: {
    config: {
      type: Object
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      row: null,
      factorList: [{
        name: '订单会员资格',
        tag: 'order_level',
        type: 'level'
      }, {
        name: '订单会员等级',
        tag: 'order_grade',
        type: 'grade'
      }, {
        name: '订单业绩',
        tag: 'order_pv',
        type: 'pv'
      }, {
        name: '奖励会员资格',
        tag: 'give_level',
        type: 'level'
      }, {
        name: '奖励会员等级',
        tag: 'give_grade',
        type: 'grade'
      }, {
        name: '奖励会员店补等级',
        tag: 'give_store',
        type: 'store'
      }, {
        name: '奖励会员团队业绩(金额必须倒序)',
        tag: 'give_pov',
        type: 'pv',
        op: '>='
      }, {
        name: '奖励会员团队复销业绩(金额必须倒序)',
        tag: 'give_pov2',
        type: 'pv',
        op: '>='
      }],
      conditions: {
        layer: 1,
        isStore: 0,
        //是否是店补奖
        computeType: 0,
        //0非极差 1极差
        bonusMode: 0,
        //收益类型：0比率 1金额
        orderTypes: [],
        //生效的订单类型
        factor: [],
        //影响因素
        bonusRateData: [],
        factorKeys: [],
        factorBonusKv: {},
        bonusRateOnly: 0
      }
    };
  },
  mounted: function mounted() {},
  methods: {
    onIsStoreChange: function onIsStoreChange(value) {
      if (value == 1) {
        this.conditions.layer = 1;
        this.computeType = 0;
      }
    },
    onClose: function onClose() {
      this.finish();
    },
    getFactorByTag: function getFactorByTag(tag) {
      for (var i in this.factorList) {
        if (this.factorList[i].tag === tag) {
          return this.factorList[i];
        }
      }

      return null;
    },
    resetFactorKeys: function resetFactorKeys() {
      //重置因素选择项，全部初始化
      var tmp = [];

      for (var i in this.conditions.factor) {
        var factor = this.getFactorByTag(this.conditions.factor[i]);
        var item = {
          name: factor.name,
          tag: factor.tag,
          type: factor.type,
          children: []
        };

        if (factor.hasOwnProperty('op')) {
          item.op = factor.op;
        }

        if (factor.type === 'grade') {
          for (var _i in this.config.gradeData) {
            var grade = this.config.gradeData[_i];
            item.children.push({
              name: grade.name,
              tag: grade.tag,
              selected: true
            });
          }
        } else if (factor.type === 'level') {
          for (var _i2 in this.config.levelData) {
            var level = this.config.levelData[_i2];
            item.children.push({
              name: level.name,
              tag: level.tag,
              type: factor.type,
              selected: true
            });
          }
        } else if (factor.type === 'store') {
          for (var _i3 in this.config.storeData) {
            var store = this.config.storeData[_i3];
            item.children.push({
              name: store.name,
              tag: store.tag,
              type: factor.type,
              selected: true
            });
          }
        }

        tmp.push(item);
      }

      this.conditions.factorKeys = tmp;
    },
    createBonusKv: function createBonusKv(isUpdate) {
      //生成因素与奖金字典 true:更新界面比率  false:重界面读取比率
      var tmp = {};

      var _this = this;

      var deepLoop = function deepLoop(item, key) {
        var factorValue = item.factorValue;
        key = key + factorValue;
        var children = item.children;

        if (children.length > 0) {
          for (var i in children) {
            var subItem = children[i];
            deepLoop(subItem, key);
          }
        } else {
          //最后一层
          if (isUpdate) {
            item.rate = _this.conditions.factorBonusKv[key];
          } else {
            tmp[key] = item.rate;
          }
        }
      };

      console.log('this.conditions.factorBonusKv', tmp);

      for (var i in this.conditions.bonusRateData) {
        deepLoop(this.conditions.bonusRateData[i], '');
      }

      if (!isUpdate) {
        this.conditions.factorBonusKv = tmp;
      }
    },
    reloadFactorData: function reloadFactorData() {
      var tmp = [];
      this.idx = 0;
      this.findNextFactor(tmp, 0);
      this.conditions.bonusRateData = tmp;
      this.createBonusKv(true);
    },
    resetFactorData: function resetFactorData() {
      var tmp = [];
      this.idx = 0;
      this.findNextFactor(tmp, 0);
      this.conditions.bonusRateData = tmp;
      this.createBonusKv(false);
    },
    findNextFactor: function findNextFactor(children, factorIndex) {
      var factor = this.conditions.factorKeys[factorIndex];

      if (!factor) {
        return;
      }

      for (var i in factor.children) {
        var item = factor.children[i];

        if (factor.type !== 'pv') {
          if (item.selected) {
            var cell = {
              factorTag: factor.tag,
              factorName: factor.name,
              factorType: factor.type,
              factorValue: item.tag,
              id: this.idx++,
              label: item.name,
              rate: 0,
              children: []
            };
            children.push(cell);
            this.findNextFactor(cell.children, factorIndex + 1);
          }
        } else {
          var label = item.pv;

          if (factor.hasOwnProperty('op')) {
            label = factor.op + item.pv;
          }

          var _cell = {
            factorTag: factor.tag,
            factorName: factor.name,
            factorType: factor.type,
            factorValue: item.pv,
            id: this.idx++,
            label: label,
            rate: 0,
            children: []
          };
          children.push(_cell);
          this.findNextFactor(_cell.children, factorIndex + 1);
        }
      }
    },
    factorChange: function factorChange() {
      this.resetFactorKeys();
    },
    show: function show(row, finish) {
      this.dialogVisible = true;
      this.finish = finish;
      this.row = row;

      if (row.conditions) {
        var tmp = this.$tempModel.deepCopy(row.conditions);

        for (var key in this.conditions) {
          if (!tmp.hasOwnProperty(key)) {
            tmp[key] = this.conditions[key];
          }
        }

        this.conditions = tmp;
        this.createBonusKv(false);
      }
    },
    clickSubmit: function clickSubmit() {
      this.row.conditions = this.conditions;
      this.dialogVisible = false;
    },
    clickDelFactorPv: function clickDelFactorPv(item, index) {
      var tmp = this.$tempModel.deepCopy(item.children);
      tmp.splice(index, 1);
      item.children = tmp;
    },
    clickAddFactorPv: function clickAddFactorPv(children) {
      children.push({
        pv: 0
      });
    }
  }
};