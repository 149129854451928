import "core-js/modules/es.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import conditionCreator from '../conditionCreator';
export default {
  components: {
    conditionCreator: conditionCreator
  },
  props: {
    gradeList: {
      type: Array
    },
    levelList: {
      type: Array
    }
  },
  data: function data() {
    return {
      name: '',
      row: null,
      dialogVisible: false,
      exaCond: [],
      exaActionSelect: '-1',
      exaAction: {
        name: '无作用',
        tag: '-1',
        params: []
      },
      exaActionList: [{
        name: '无作用',
        tag: '-1',
        params: []
      }, {
        name: '考核记录(记录考核日志，不发放奖金)',
        tag: 'exa_record',
        params: []
      }]
    };
  },
  mounted: function mounted() {},
  methods: {
    onClose: function onClose() {
      this.finish();
    },
    show: function show(row, finish) {
      var _this = this;

      this.row = row;
      this.name = row.name;

      if (this.row.exaAction && this.row.exaAction !== '') {
        this.exaAction = this.$tempModel.deepCopy(this.row.exaAction);
        this.exaActionSelect = this.exaAction.tag;
      }

      if (this.row.exaCond && this.row.exaCond !== '') {
        this.exaCond = this.$tempModel.deepCopy(this.row.exaCond);
      }

      this.dialogVisible = true;
      this.finish = finish;
      this.$nextTick(function () {
        _this.$refs.creator.setCondData(_this.$tempModel.deepCopy(_this.exaCond));
      });
    },
    exaActionChange: function exaActionChange(value) {
      for (var i in this.exaActionList) {
        var item = this.exaActionList[i];

        if (item.tag === value) {
          this.exaAction = this.$tempModel.deepCopy(item);
          break;
        }
      }
    },
    clickSubmit: function clickSubmit() {
      this.row.exaCond = this.$refs.creator.getCondData();
      this.row.exaAction = this.exaAction;
      this.dialogVisible = false;
      this.finish();
    }
  }
};