var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "会员补扣积分",
        visible: _vm.dialogVisible,
        width: "500px",
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "show-close": false
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        }
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "max-height": "50vh",
            "overflow-y": "auto",
            padding: "15px 30px",
            "background-color": "#f6f6f6"
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                rules: _vm.rules,
                "label-width": "100px"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "会员ID" } },
                [_c("el-input", { attrs: { value: _vm.form.number } })],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "操作类型" } },
                [
                  _c(
                    "el-select",
                    {
                      attrs: { placeholder: "请选择" },
                      model: {
                        value: _vm.form.tag,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "tag", $$v)
                        },
                        expression: "form.tag"
                      }
                    },
                    _vm._l(_vm.tagList, function(item) {
                      return _c("el-option", {
                        key: item.key,
                        attrs: { label: item.value, value: item.key }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "补扣积分", prop: "happen_money" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.happen_money,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "happen_money", $$v)
                      },
                      expression: "form.happen_money"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "备注", prop: "remark" } },
                [
                  _c("el-input", {
                    model: {
                      value: _vm.form.remark,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "remark", $$v)
                      },
                      expression: "form.remark"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.clickSubmit } },
            [_vm._v("提交")]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }