var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.name + "奖金配置",
        visible: _vm.dialogVisible,
        width: "60%",
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "destroy-on-close": true,
        "show-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.onClose
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            "max-height": "50vh",
            "overflow-y": "auto",
            padding: "15px 30px",
            "background-color": "#f6f6f6"
          }
        },
        [
          _vm.bonusType == 0
            ? _c("tuijian", {
                ref: "cond" + _vm.bonusType,
                attrs: {
                  conditions: _vm.conditions,
                  "level-data": _vm.config.levelData
                }
              })
            : _vm._e(),
          _vm.bonusType == 1
            ? _c("jiandian", {
                ref: "cond" + _vm.bonusType,
                attrs: {
                  conditions: _vm.conditions,
                  "level-data": _vm.config.levelData
                }
              })
            : _vm._e(),
          _vm.bonusType == 2
            ? _c("jicha", {
                ref: "cond" + _vm.bonusType,
                attrs: {
                  conditions: _vm.conditions,
                  "grade-data": _vm.config.gradeData
                }
              })
            : _vm._e(),
          _vm.bonusType == 3
            ? _c("dingping", {
                ref: "cond" + _vm.bonusType,
                attrs: {
                  conditions: _vm.conditions,
                  "grade-data": _vm.config.gradeData
                }
              })
            : _vm._e(),
          _vm.bonusType == 4
            ? _c("fenhong", {
                ref: "cond" + _vm.bonusType,
                attrs: {
                  conditions: _vm.conditions,
                  "grade-data": _vm.config.gradeData
                }
              })
            : _vm._e(),
          _vm.bonusType == 5
            ? _c("dianbu", {
                ref: "cond" + _vm.bonusType,
                attrs: {
                  conditions: _vm.conditions,
                  "store-data": _vm.config.storeData
                }
              })
            : _vm._e(),
          _vm.bonusType == 6
            ? _c("equalfenhong", {
                ref: "cond" + _vm.bonusType,
                attrs: {
                  conditions: _vm.conditions,
                  "grade-data": _vm.config.gradeData
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "mini" },
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: { click: _vm.clickSubmit }
            },
            [_vm._v(_vm._s("保 存"))]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }