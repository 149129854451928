import "core-js/modules/es.function.name";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tuijian from "./bounsCond/tuijian";
import jicha from "./bounsCond/jicha";
import jiandian from "./bounsCond/jiandian";
import fenhong from "./bounsCond/fenhong";
import dingping from "./bounsCond/dingping";
import dianbu from "./bounsCond/dianbu";
import equalfenhong from './bounsCond/equalfenhong';
export default {
  components: {
    tuijian: tuijian,
    jicha: jicha,
    jiandian: jiandian,
    fenhong: fenhong,
    dingping: dingping,
    dianbu: dianbu,
    equalfenhong: equalfenhong
  },
  props: {
    config: {
      type: Object
    }
  },
  data: function data() {
    return {
      conditions: null,
      name: '',
      bonusType: '',
      dialogVisible: false,
      data: []
    };
  },
  mounted: function mounted() {},
  methods: {
    onClose: function onClose() {
      this.finish();
    },
    show: function show(row, finish) {
      this.row = row;
      this.finish = finish;
      this.conditions = row.conditions;
      this.name = row.name;
      this.bonusType = row.bonusType;
      this.dialogVisible = true;
    },
    clickSubmit: function clickSubmit() {
      this.dialogVisible = false;
      console.log('this.$refs[\'cond\'+this.bonusType]', this.$refs['cond' + this.bonusType], 'cond' + this.bonusType);
      var params = this.$refs['cond' + this.bonusType].getParams();
      this.row.conditions = params;
    }
  }
};