import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import gradeConfig from './systemConfig/gradeConfig/index';
import baseConfig from './systemConfig/baseConfig/index';
import bonusConfig from './systemConfig/bonusConfig/index';
import levelConfig from './systemConfig/levelConfig/index';
import manageTaxConfig from './systemConfig/manageTaxConfig/index';
import resaleTaxConfig from './systemConfig/resaleTaxConfig/index';
import storeConfig from './systemConfig/storeConfig/index';
import { systemTempAdd, systemTempDetail, systemTempDetailAdd } from "../../../../api/apis";
export default {
  components: {
    gradeConfig: gradeConfig,
    baseConfig: baseConfig,
    bonusConfig: bonusConfig,
    levelConfig: levelConfig,
    manageTaxConfig: manageTaxConfig,
    resaleTaxConfig: resaleTaxConfig,
    storeConfig: storeConfig
  },
  data: function data() {
    return {
      row: null,
      dialogVisible: false,
      activeName: '-1',
      config: null
    };
  },
  methods: {
    show: function show(row, finish, cbClose) {
      this.row = row;
      this.dataChangeCount = -1;

      if (row.config === '' || row.config == null) {
        this.initConfig();
      } else {
        this.config = JSON.parse(row.config);
        console.log('this.config', this.config);
      }

      console.log('this.row', this.row);
      this.finish = finish;
      this.cbClose = cbClose;
      this.dialogVisible = true;
    },
    onClose: function onClose() {
      this.cbClose();
    },
    initConfig: function initConfig() {
      this.config = {
        baseData: {
          memberBdType: [],
          centerBdType: [],
          canCrossBd: 0,
          centerBdOnly: 0,
          takeTax: 0,
          additionalProduct: 0,
          bonusStoreOrder2: 0,
          bonusStoreOrder3: 0,
          bonusStoreOrder4: 0,
          bonusStoreOwn: 0,
          levelCanSkip: 0,
          //是否允许跨级升
          levelValidDay: 0,
          //升级有效日期
          levelModel: 0,
          //升级模式，补差、全额
          gradeValidType: 0,
          //升级生效类型 0当日 1次日
          manageTax: [],
          resaleTax: [],
          resaleMultiple: 1,
          storeCancelOrder: 0 //服务中心是否可以撤单

        },
        levelId: 0,
        levelData: [],
        storeId: 0,
        storeData: [],
        //店补等级
        gradeId: 0,
        gradeData: [],
        bonusId: 0,
        bonusData: []
      };
    },
    clickSubmit: function clickSubmit() {
      var _this = this;

      this.$confirm('确定更新当前模板?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        systemTempAdd({
          id: _this.row.id,
          config: JSON.stringify(_this.config),
          name: _this.row.name,
          remark: _this.row.remark
        }).then(function _callee(res) {
          return regeneratorRuntime.async(function _callee$(_context) {
            while (1) {
              switch (_context.prev = _context.next) {
                case 0:
                  _this.$alert('保存成功', '保存模板', {
                    confirmButtonText: '确定',
                    callback: function callback(action) {
                      _this.isSaved = true;

                      _this.finish();
                    }
                  });

                case 1:
                case "end":
                  return _context.stop();
              }
            }
          });
        });
      });
    },
    clickCreate: function clickCreate() {
      var _this2 = this;

      if (!this.isSaved) {
        this.$alert('请先保存模板然后在提交', '提示', {
          confirmButtonText: '确定',
          callback: function callback(action) {}
        });
        return;
      }

      this.$confirm('确定生成或更新当前系统么?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function () {
        systemTempDetailAdd({
          id: _this2.row.id
        }).then(function _callee2(res) {
          return regeneratorRuntime.async(function _callee2$(_context2) {
            while (1) {
              switch (_context2.prev = _context2.next) {
                case 0:
                  _this2.$alert('生成成功', '生成系统', {
                    confirmButtonText: '确定',
                    callback: function callback(action) {
                      _this2.isSaved = false;
                    }
                  });

                case 1:
                case "end":
                  return _context2.stop();
              }
            }
          });
        });
      });
    }
  }
};