var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "d2-container",
    [
      _c(
        "el-card",
        [
          _c(
            "el-form",
            { ref: "form", attrs: { model: _vm.config } },
            [
              _c(
                "el-form-item",
                { attrs: { label: "自然晋级生效时间" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.config.baseData.gradeValidType,
                        callback: function($$v) {
                          _vm.$set(_vm.config.baseData, "gradeValidType", $$v)
                        },
                        expression: "config.baseData.gradeValidType"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("当日")]),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("次日")])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c(
            "temp-table",
            _vm._b(
              {
                ref: "ref_1000",
                attrs: {
                  "remote-func": _vm.remoteFunc,
                  "req-table-data": _vm.reqTableData,
                  "req-add": _vm.reqAdd,
                  "req-del": _vm.reqDel,
                  "on-click-link": _vm.onClickLink,
                  "on-click-row-button": _vm.onClickRowButton,
                  "on-click-header-button": _vm.onClickHeaderButton,
                  "on-summary": _vm.onSummary,
                  "on-switch-change": _vm.onSwitchChange,
                  "on-row-button-show": _vm.onRowButtonShow
                }
              },
              "temp-table",
              _vm.tableModel_1000,
              false
            )
          )
        ],
        1
      ),
      _c("grade-cond", {
        key: _vm.reloadTimer1,
        ref: "cond",
        attrs: {
          "grade-list": _vm.config.gradeData,
          "level-list": _vm.config.levelData
        }
      }),
      _c("exa-cond", {
        key: _vm.reloadTimer2,
        ref: "exa",
        attrs: {
          "grade-list": _vm.config.gradeData,
          "level-list": _vm.config.levelData
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }