import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "core-js/modules/web.dom-collections.iterator";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import systemAdd from "./systemAdd";
import { systemTempList, systemTempAdd, systemTempDel } from '../../../../api/apis';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart,
    systemAdd: systemAdd
  },
  data: function data() {
    var _this = this;

    return {
      reloadTimer: '',
      tableModel_1000: {
        "border": true,
        "del": false,
        "headerButton": [{
          "id": "",
          "tag": "add",
          "title": "添加",
          "type": "primary"
        }],
        "id": "id_1000",
        "isGroup": false,
        "isTree": false,
        "rowButton": [{
          "icon": "",
          "id": "",
          "tag": "default",
          "title": "系统配置",
          "type": "success"
        }, {
          "id": "",
          "tag": "edit",
          "title": "编辑",
          "type": "primary"
        }, {
          "id": "",
          "tag": "del",
          "title": "删除",
          "type": "danger"
        }],
        "showPagination": false,
        "showSelection": false,
        "showSummary": false,
        "stripe": false,
        "tableAutocompleteProps": {},
        "tableCascaderProps": {},
        "tableFileProps": {},
        "tableModel": [{
          "label": "",
          "show": 1,
          "add": 0,
          "detail": 1,
          "width": "60",
          "type": "custom",
          "desc": "",
          "groupId": "",
          "rules": [],
          "prop": "color_show"
        }, {
          "add": 1,
          "desc": "系统唯一标识",
          "detail": 1,
          "groupId": "",
          "label": "ID",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "id"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "系统状态",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "select",
          "width": "100",
          "prop": "status"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "系统名称",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "name"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "添加日期",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "add_time"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "生成日期",
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "150",
          "prop": "create_time"
        }, {
          "add": 0,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "模板内容",
          "rules": [],
          "show": 0,
          "type": "text",
          "width": "100",
          "prop": "config"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "编号前缀",
          "required": 1,
          "rules": [],
          "show": 1,
          "type": "text",
          "width": "80",
          "prop": "prefix"
        }, {
          "add": 1,
          "desc": "",
          "detail": 1,
          "groupId": "",
          "label": "备注",
          "rules": [],
          "show": 1,
          "type": "textarea",
          "width": "250",
          "prop": "remark"
        }, {
          "label": "公共复销系统",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": "100",
          "type": "select",
          "desc": "",
          "groupId": "",
          "rules": [],
          "prop": "is_fuxiao"
        }, {
          "label": "系统颜色",
          "show": 1,
          "add": 1,
          "detail": 1,
          "width": "100",
          "type": "select",
          "desc": "",
          "groupId": "",
          "rules": [],
          "prop": "color"
        }],
        "tableName": "",
        "tableRadioProps": {},
        "tableSelectProps": {
          "status": {
            "data": [{
              "key": 0,
              "value": "未使用"
            }, {
              "key": 1,
              "value": "测试中"
            }, {
              "key": 2,
              "value": "已上线"
            }],
            "id": 0,
            "multiple": 0,
            "relation": false,
            "relationProps": [],
            "remote": false
          },
          "is_fuxiao": {
            "multiple": 0,
            "data": [{
              "key": 0,
              "value": "否"
            }, {
              "key": 1,
              "value": "是"
            }],
            "remote": false,
            "id": 1,
            "relation": false,
            "relationProps": []
          },
          "color": {
            "multiple": 0,
            "data": [{
              "key": "black",
              "value": "墨黑"
            }, {
              "key": "rf",
              "value": "粉色"
            }, {
              "key": "red",
              "value": "嫣红"
            }, {
              "key": "orange",
              "value": "桔橙"
            }, {
              "key": "yellow",
              "value": "明黄"
            }, {
              "key": "olive",
              "value": "橄榄"
            }, {
              "key": "green",
              "value": "森绿"
            }, {
              "key": "cyan",
              "value": "天青"
            }, {
              "key": "blue",
              "value": "海蓝"
            }, {
              "key": "purple",
              "value": "姹紫"
            }, {
              "key": "mauve",
              "value": "木槿"
            }, {
              "key": "pink",
              "value": "桃粉"
            }, {
              "key": "brown",
              "value": "棕褐"
            }, {
              "key": "grey",
              "value": "玄灰"
            }, {
              "key": "gray",
              "value": "草灰"
            }],
            "remote": false,
            "id": 2,
            "relation": false,
            "relationProps": []
          }
        },
        "tableSwitchProps": {},
        "title": "系统配置模板",
        "type": "tableTemplate"
      },
      remoteFunc: {},
      data: [{
        name: '测试模板',
        id: 1,
        add_time: '2021-01-10',
        create_time: '',
        config: ''
      }],
      themeList: [{
        title: '墨黑',
        name: 'black',
        color: '#333333'
      }, {
        title: '粉色',
        name: 'rf',
        color: '#fa436a'
      }, {
        title: '嫣红',
        name: 'red',
        color: '#e54d42'
      }, {
        title: '桔橙',
        name: 'orange',
        color: '#f37b1d'
      }, {
        title: '明黄',
        name: 'yellow',
        color: '#fbbd08'
      }, {
        title: '橄榄',
        name: 'olive',
        color: '#8dc63f'
      }, {
        title: '森绿',
        name: 'green',
        color: '#39b54a'
      }, {
        title: '天青',
        name: 'cyan',
        color: '#1cbbb4'
      }, {
        title: '海蓝',
        name: 'blue',
        color: '#0081ff'
      }, {
        title: '姹紫',
        name: 'purple',
        color: '#6739b6'
      }, {
        title: '木槿',
        name: 'mauve',
        color: '#9c26b0'
      }, {
        title: '桃粉',
        name: 'pink',
        color: '#e03997'
      }, {
        title: '棕褐',
        name: 'brown',
        color: '#a5673f'
      }, {
        title: '玄灰',
        name: 'grey',
        color: '#8799a3'
      }, {
        title: '草灰',
        name: 'gray',
        color: '#aaaaaa'
      }]
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1000;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var _this2 = this;

      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      systemTempList(params).then(function _callee(res) {
        var i, item, j;
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _context.t0 = regeneratorRuntime.keys(res);

              case 1:
                if ((_context.t1 = _context.t0()).done) {
                  _context.next = 14;
                  break;
                }

                i = _context.t1.value;
                item = res[i];
                _context.t2 = regeneratorRuntime.keys(_this2.themeList);

              case 5:
                if ((_context.t3 = _context.t2()).done) {
                  _context.next = 12;
                  break;
                }

                j = _context.t3.value;

                if (!(item.color === _this2.themeList[j].name)) {
                  _context.next = 10;
                  break;
                }

                item.color_show = '<div style="width:20px;height:20px;background-color:' + _this2.themeList[j].color + '"></div>';
                return _context.abrupt("break", 12);

              case 10:
                _context.next = 5;
                break;

              case 12:
                _context.next = 1;
                break;

              case 14:
                resolve({
                  total: 0,
                  data: res
                });

              case 15:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {
      if (isEdit) {
        params.id = row.id;
      }

      systemTempAdd(params).then(function _callee2(res) {
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      });
      systemTempDel({
        id: ids[0]
      }).then(function _callee3(res) {
        return regeneratorRuntime.async(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context3.stop();
            }
          }
        });
      });
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {
      var _this3 = this;

      //点击列表按钮回调
      if (btnIndex === 0) {
        this.$refs['add'].show(row, function () {
          _this3.table.reloadData();
        }, function () {
          _this3.reloadTimer = new Date().getTime();
        });
      }
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {//点击列表头部按钮回调
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      return true;
    }
  }
};