import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from '@/components/rw/tempTable/index';
import tempSearch from '@/components/rw/tempSearch/index';
import { productCategory, productCategoryAdd, productCategoryDel } from '../../../../api/apis';
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch
  },
  data: function data() {
    var _this = this;

    return {
      searchModel_1000: {
        'del': false,
        'id': 'id_1000',
        'operateData': [{
          'icon': 'el-icon-search',
          'tag': 'search',
          'title': '搜索',
          'type': 'primary'
        }, {
          'icon': 'el-icon-position',
          'tag': 'export',
          'title': '导出',
          'type': 'primary'
        }],
        'searchData': [{
          'children': [{
            'field': [{
              'desc': '分类名称',
              'key': 'cate_name',
              'value': ''
            }],
            'placeholder': '请输入分类名称',
            'type': 'input-complex'
          }],
          'id': 0,
          'relation': false,
          'relationId': '',
          'remote': false,
          'remoteType': 0,
          'select': 0,
          'title': '按名称',
          'type': 2,
          'remoteFunc': []
        }],
        'type': 'searchTemplate'
      },
      tableModel_1001: {
        'border': true,
        'defaultExpandAll': true,
        'del': false,
        'headerButton': [{
          'id': '',
          'tag': 'add',
          'title': '添加',
          'type': 'primary'
        }],
        'id': 'id_1001',
        'isTree': true,
        'rowButton': [{
          'id': '',
          'tag': 'edit',
          'title': '编辑',
          'type': 'primary'
        }, {
          'id': '',
          'tag': 'del',
          'title': '删除',
          'type': 'danger'
        }],
        'rowKey': 'id',
        'showPagination': true,
        'showSelection': false,
        'showSummary': false,
        'stripe': false,
        'tableModel': [{
          'add': 0,
          'detail': 1,
          'excel': 1,
          'label': 'ID',
          'required': 0,
          'rowAddField': '',
          'show': 1,
          'type': 'text',
          'width': '80',
          'groupId': '',
          'rules': [],
          'prop': 'id'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'label': '父id',
          'required': 1,
          'rowAddField': 'id',
          'show': 0,
          'type': 'select',
          'width': 120,
          'groupId': '',
          'rules': [],
          'prop': 'pid'
        }, {
          'add': 1,
          'detail': 1,
          'excel': 1,
          'label': '分类名称',
          'required': 1,
          'rowAddField': '',
          'show': 1,
          'type': 'text',
          'width': '120',
          'groupId': '',
          'rules': [],
          'prop': 'cate_name'
        }, {
          'add': 1,
          'defaultValue': 100,
          'detail': 1,
          'excel': 1,
          'label': '排序',
          'required': 1,
          'rowAddField': '',
          'show': 1,
          'type': 'text',
          'width': 80,
          'groupId': '',
          'rules': [],
          'prop': 'sort'
        }, {
          'add': 0,
          'detail': 1,
          'excel': 1,
          'label': '添加时间',
          'required': 0,
          'rowAddField': '',
          'show': 1,
          'type': 'text',
          'width': 160,
          'groupId': '',
          'rules': [],
          'prop': 'add_time'
        }, {
          'add': 1,
          'defaultValue': 0,
          'detail': 1,
          'excel': 1,
          'label': '个人中心显示',
          'required': 0,
          'rowAddField': '',
          'show': 1,
          'type': 'select',
          'width': 120,
          'groupId': '',
          'rules': [],
          'prop': 'in_me'
        }],
        'tableName': 't_store_category',
        'tableSelectProps': {
          'pid': {
            'data': [{
              'key': null,
              'value': ''
            }],
            'id': 1,
            'multiple': 0,
            'relation': false,
            'relationId': '',
            'remote': true,
            'remoteFunc': 'tableFunc_select_pid'
          },
          'in_me': {
            'data': [{
              'key': 0,
              'value': '否'
            }, {
              'key': 1,
              'value': '是'
            }],
            'id': 2,
            'multiple': 0,
            'relation': false,
            'relationProps': [],
            'remote': false
          }
        },
        'tableSwitchProps': {},
        'title': '分类',
        'treeProps': {
          'children': 'sub',
          'hasChildren': 'hasChildren'
        },
        'type': 'tableTemplate',
        'tableRadioProps': {},
        'tableCascaderProps': {}
      },
      remoteFunc: {
        tableFunc_select_pid: function tableFunc_select_pid(tag, relationData, resolve) {
          //筛选项模型数组
          //[{ key: "新品", value: "0" }]
          productCategory({}).then(function _callee(res) {
            var data, menu, i;
            return regeneratorRuntime.async(function _callee$(_context) {
              while (1) {
                switch (_context.prev = _context.next) {
                  case 0:
                    data = [{
                      key: 0,
                      value: '根菜单'
                    }];
                    menu = _this.treeToList(res, 'sub');

                    for (i in menu) {
                      data.push({
                        key: menu[i].id,
                        value: menu[i].longName
                      });
                    }

                    console.log('tableSelectFunc_pid', data, menu);
                    resolve(tag, data);

                  case 5:
                  case "end":
                    return _context.stop();
                }
              }
            });
          });
        }
      }
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1001;
    this.search = this.$refs.ref_1000;
  },
  methods: {
    treeToList: function treeToList(tree, children) {
      var ary = [];

      var addRow = function addRow(obj, children, parentName) {
        var item = JSON.parse(JSON.stringify(obj));

        if (parentName === '') {
          item.longName = item.cate_name;
        } else {
          item.longName = parentName + '-' + item.cate_name;
        }

        delete item[children];
        ary.push(item);
        var sub = obj[children];

        for (var i in sub) {
          addRow(sub[i], children, item.longName);
        }
      };

      for (var i in tree) {
        addRow(tree[i], children, '');
      }

      return ary;
    },
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      productCategory(params).then(function _callee2(res) {
        return regeneratorRuntime.async(function _callee2$(_context2) {
          while (1) {
            switch (_context2.prev = _context2.next) {
              case 0:
                resolve({
                  total: 0,
                  data: res
                });

              case 1:
              case "end":
                return _context2.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {
      if (isEdit) {
        params.id = row.id;
      }

      console.log('apr', params);
      productCategoryAdd(params).then(function _callee3(res) {
        return regeneratorRuntime.async(function _callee3$(_context3) {
          while (1) {
            switch (_context3.prev = _context3.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context3.stop();
            }
          }
        });
      });
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      });
      productCategoryDel({
        id: ids
      }).then(function _callee4(res) {
        return regeneratorRuntime.async(function _callee4$(_context4) {
          while (1) {
            switch (_context4.prev = _context4.next) {
              case 0:
                resolve();

              case 1:
              case "end":
                return _context4.stop();
            }
          }
        });
      });
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {//点击列表按钮回调
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {//点击列表头部按钮回调
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = data.header;
        var results = data.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    }
  }
};