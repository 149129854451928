import "core-js/modules/es.object.to-string";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { accountCodeBind } from '../../../api/apis';
import md5 from 'js-md5';
import setting from '../../../setting';
export default {
  data: function data() {
    return {
      dialogVisible1: false,
      dialogVisible2: false,
      captchaUrl: setting.appApi.captcha,
      rulesBind: {
        account: [{
          required: true,
          message: '请输入账号',
          trigger: 'blur'
        }],
        pwd: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        }],
        captcha: [{
          required: true,
          message: '请输验证码',
          trigger: 'blur'
        }]
      },
      qrcodePath: '',
      bindData: {
        account: '',
        pwd: '',
        captcha: ''
      }
    };
  },
  methods: {
    changeCaptcha: function changeCaptcha() {
      this.captchaUrl = setting.appApi.captcha + '?tp=' + new Date().getTime();
    },
    show: function show() {
      this.changeCaptcha();
      this.dialogVisible1 = true;
    },
    clickBind: function clickBind() {
      var _this = this;

      var account = this.bindData.account;
      var pwd = md5(this.bindData.pwd);
      accountCodeBind({
        account: account,
        pwd: pwd,
        captcha: this.bindData.captcha
      }).then(function _callee(res) {
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                _this.dialogVisible2 = true;
                _this.dialogVisible1 = false;
                _this.qrcodePath = res.url;

              case 3:
              case "end":
                return _context.stop();
            }
          }
        });
      }).catch(function (e) {
        _this.changeCaptcha();
      });
    }
  }
};