var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-card",
    [
      _c(
        "el-card",
        [
          _c(
            "el-form",
            { ref: "form", attrs: { size: "mini", "label-position": "top" } },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      "会员本人报的升级单，是否用于给所属服务中心计算税务补贴"
                  }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.config.baseData.bonusStoreOrder2,
                        callback: function($$v) {
                          _vm.$set(_vm.config.baseData, "bonusStoreOrder2", $$v)
                        },
                        expression: "config.baseData.bonusStoreOrder2"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      "会员本人报的复销单，是否用于给所属服务中心计算税务补贴"
                  }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.config.baseData.bonusStoreOrder3,
                        callback: function($$v) {
                          _vm.$set(_vm.config.baseData, "bonusStoreOrder3", $$v)
                        },
                        expression: "config.baseData.bonusStoreOrder3"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      "会员本人报的激活单，是否用于给所属服务中心（该服务中心对应会员必须在该系统处于激活状态）"
                  }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.config.baseData.bonusStoreOrder4,
                        callback: function($$v) {
                          _vm.$set(_vm.config.baseData, "bonusStoreOrder4", $$v)
                        },
                        expression: "config.baseData.bonusStoreOrder4"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [_vm._v("否")]),
                      _c("el-radio", { attrs: { label: 1 } }, [_vm._v("是")])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label:
                      "服务中心给会员报单时，税务补贴归报单的服务中心，还是归会员所属服务中心"
                  }
                },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.config.baseData.bonusStoreOwn,
                        callback: function($$v) {
                          _vm.$set(_vm.config.baseData, "bonusStoreOwn", $$v)
                        },
                        expression: "config.baseData.bonusStoreOwn"
                      }
                    },
                    [
                      _c("el-radio", { attrs: { label: 0 } }, [
                        _vm._v("归报单者")
                      ]),
                      _c("el-radio", { attrs: { label: 1 } }, [
                        _vm._v("归所属者")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        { staticStyle: { "margin-top": "20px" } },
        [
          _c(
            "temp-table",
            _vm._b(
              {
                ref: "ref_1000",
                attrs: {
                  "remote-func": _vm.remoteFunc,
                  "req-table-data": _vm.reqTableData,
                  "req-add": _vm.reqAdd,
                  "req-del": _vm.reqDel,
                  "on-click-link": _vm.onClickLink,
                  "on-click-row-button": _vm.onClickRowButton,
                  "on-click-header-button": _vm.onClickHeaderButton,
                  "on-summary": _vm.onSummary,
                  "on-switch-change": _vm.onSwitchChange,
                  "on-row-button-show": _vm.onRowButtonShow
                }
              },
              "temp-table",
              _vm.tableModel_1000,
              false
            )
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }