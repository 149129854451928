import "core-js/modules/es.array.index-of";
import "core-js/modules/es.parse-int";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import searchButton from './components/searchButton';
import searchButtonDate from './components/searchButtonDate';
import searchInput from './components/searchInput';
import searchInputComplex from './components/searchInputComplex';
import searchInputMulComplex from './components/searchInputMulComplex';
import searchSelect from './components/searchSelect';
import searchTimeRange from './components/searchTimeRange';
export default {
  components: {
    searchButton: searchButton,
    searchButtonDate: searchButtonDate,
    searchInput: searchInput,
    searchInputComplex: searchInputComplex,
    searchInputMulComplex: searchInputMulComplex,
    searchSelect: searchSelect,
    searchTimeRange: searchTimeRange
  },
  props: {
    searchData: {
      type: Array,
      required: true
    },
    operateData: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    remoteFunc: {
      type: Object,
      default: function _default() {
        return {};
      }
    },
    onClickOperate: {
      type: Function
    },
    onConditionChange: {
      type: Function
    }
  },
  data: function data() {
    return {};
  },
  watch: {},
  created: function created() {},
  mounted: function mounted() {
    for (var i = 0; i < this.searchData.length; i++) {
      var rd = this._relationData(this.searchData[i].id);

      this._callRemoteFunc(i, rd);
    }
  },
  methods: {
    curComponent: function curComponent(type) {
      if (type === this.$tempModel.searchType.input) {
        return 'searchInput';
      } else if (type === this.$tempModel.searchType.inputComplex) {
        return 'searchInputComplex';
      } else if (type === this.$tempModel.searchType.inputMulComplex) {
        return 'searchInputMulComplex';
      } else if (type === this.$tempModel.searchType.button || type === this.$tempModel.searchType.buttonNumberMajor || type === this.$tempModel.searchType.buttonNumber) {
        return 'searchButton';
      } else if (type === this.$tempModel.searchType.customDateRange || type === this.$tempModel.searchType.customDatetimeRange) {
        return 'searchButtonDate';
      } else if (type === this.$tempModel.searchType.select) {
        return 'searchSelect';
      } else if (type === this.$tempModel.searchType.customTimeRange) {
        return 'searchTimeRange';
      }
    },
    isButtonSelect: function isButtonSelect(tag, index) {
      return this.searchData[tag].select === index;
    },
    parseWhere: function parseWhere() {
      var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
      var where = {};

      for (var i in this.searchData) {
        var section = this.searchData[i];
        var children = section.children;

        if (id != null) {
          if (section.id != id) {
            continue;
          }
        }

        for (var j in children) {
          var item = children[j];
          var key = 'item_' + i + '_' + j;
          var obj = this.$refs[key][0];

          if (item.type === this.$tempModel.searchType.button || item.type === this.$tempModel.searchType.buttonNumber || item.type === this.$tempModel.searchType.buttonNumberMajor || item.type === this.$tempModel.searchType.customDateRange || item.type === this.$tempModel.searchType.customDatetimeRange) {
            if (this.searchData[i].select != j) {
              continue;
            }
          }

          if (obj) {
            var kv = obj.where();

            for (var filed in kv) {
              var value = kv[filed];
              var rightValue = true;

              if (typeof value !== 'number') {
                if (value === null || value.length <= 0) {
                  rightValue = false;
                }
              }

              if (rightValue) {
                where[filed] = value;
              }
            }
          }
        }
      }

      return where;
    },
    selectChange: function selectChange(tag, index) {
      var section = this.searchData[tag];

      if (section.relation) {
        //有关联
        this._callRemoteFuncById(section.relationId);
      }

      if (this.onConditionChange) {
        this.onConditionChange(tag, index);
      }
    },
    clickReload: function clickReload() {
      this.$tempModel.reload();
    },
    clickItem: function clickItem(tag, index) {
      this.searchData[tag].select = index;
      var section = this.searchData[tag];

      if (section.relation) {
        //有关联
        this._callRemoteFuncById(section.relationId, this._relationData(section.relationId));
      }

      if (this.onConditionChange) {
        this.onConditionChange(tag, index);
      }
    },
    clickOperate: function clickOperate(index) {
      if (this.onClickOperate) {
        var data = this.operateData[index];

        if (data.tag === this.$tempModel.buttonTag.import) {
          this.$refs.import.value = null; //解决change事件在同一个文件下不触发的问题

          this.curImportIndex = index;
          this.$refs.import.click();
        } else {
          this.onClickOperate(index, data);
        }
      }
    },
    onExcelImport: function onExcelImport(event) {
      var _this = this;

      var file = event.target.files[0];
      this.$import.xlsx(file).then(function (_ref) {
        var header = _ref.header,
            results = _ref.results;

        for (var i in _this.operateData) {
          var item = _this.operateData[i];

          if (item.tag === _this.$tempModel.buttonTag.import) {
            if (i == _this.curImportIndex) {
              var data = _this.$tempModel.deepCopy(item);

              data.header = header;
              data.results = results;

              _this.onClickOperate(parseInt(i), data);
            }
          }
        }
      }); // 阻止默认的上传

      return false;
    },
    _callRemoteFunc: function _callRemoteFunc(index, relationData) {
      var _this2 = this;

      if (index < 0) {
        return;
      }

      if (index >= this.searchData.length) {
        return;
      }

      var section = this.searchData[index];

      for (var j in section.remoteFunc) {
        var model = section.remoteFunc[j];

        if (model.indexOf('modelFunc_') >= 0) {
          if (this.remoteFunc.hasOwnProperty(model)) {
            this.remoteFunc[model](index, relationData, function (tag, data) {
              _this2.searchData[tag].children = data;
            });
          }
        }

        if (model.indexOf('modelConditionFunc_') >= 0) {
          if (this.remoteFunc.hasOwnProperty(model)) {
            this.remoteFunc[model](index, relationData, function (tag, data) {
              for (var k in data) {
                _this2.searchData[tag].children[k].field = data[k];
              }
            });
          }
        }

        if (model.indexOf('modelTotalFunc_') >= 0) {
          if (this.remoteFunc.hasOwnProperty(model)) {
            this.remoteFunc[model](index, relationData, function (tag, data) {
              for (var k in data) {
                _this2.searchData[tag].children[k].toltal = data[k];
              }
            });
          }
        }
      }
    },
    _callRemoteFuncById: function _callRemoteFuncById(id, relationData) {
      var index = this._rowIndexById(id);

      this._callRemoteFunc(index, relationData);
    },
    _relationData: function _relationData(id) {
      //关联条件的数据(谁关联指向了id)
      for (var i = 0; i < this.searchData.length; i++) {
        var section = this.searchData[i];

        if (section.relationId == id) {
          return this.parseWhere(section.id);
        }
      }

      return null;
    },
    _rowIndexById: function _rowIndexById(id) {
      for (var i = 0; i < this.searchData.length; i++) {
        if (this.searchData[i].id == id) {
          return i;
        }
      }

      return -1;
    }
  }
};