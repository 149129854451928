var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-card",
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: { model: _vm.form, size: "mini", "label-position": "top" }
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "生效的订单类型" } },
                [
                  _c(
                    "el-checkbox-group",
                    {
                      model: {
                        value: _vm.form.orderTypes,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "orderTypes", $$v)
                        },
                        expression: "form.orderTypes"
                      }
                    },
                    [
                      _c("el-checkbox", { attrs: { label: 1, name: "type" } }, [
                        _vm._v("注册单")
                      ]),
                      _c("el-checkbox", { attrs: { label: 2, name: "type" } }, [
                        _vm._v("复销单")
                      ]),
                      _c("el-checkbox", { attrs: { label: 3, name: "type" } }, [
                        _vm._v("升级单")
                      ]),
                      _c("el-checkbox", { attrs: { label: 4, name: "type" } }, [
                        _vm._v("激活单")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-card",
        { staticStyle: { "margin-top": "10px" } },
        [
          _c(
            "el-table",
            { staticStyle: { width: "100%" }, attrs: { data: _vm.data } },
            [
              _c("el-table-column", {
                attrs: { label: "会员级别", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              size: "mini",
                              placeholder: "请选择会员级别"
                            },
                            model: {
                              value: scope.row.grade,
                              callback: function($$v) {
                                _vm.$set(scope.row, "grade", $$v)
                              },
                              expression: "scope.row.grade"
                            }
                          },
                          _vm._l(_vm.gradeData, function(item) {
                            return _c("el-option", {
                              key: item.tag,
                              attrs: { label: item.name, value: item.tag }
                            })
                          }),
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "提成比例", width: "180" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-row",
                          [
                            _c("el-input-number", {
                              staticStyle: { width: "80%" },
                              attrs: { size: "mini", min: 0, max: 100 },
                              model: {
                                value: scope.row.rate,
                                callback: function($$v) {
                                  _vm.$set(scope.row, "rate", $$v)
                                },
                                expression: "scope.row.rate"
                              }
                            }),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "10px" } },
                              [_vm._v("%")]
                            )
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              }),
              _c("el-table-column", {
                attrs: { label: "操作" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function(scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "danger" },
                            on: {
                              click: function($event) {
                                return _vm.clickDelete(scope.$index, scope.row)
                              }
                            }
                          },
                          [_vm._v("删除 ")]
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "100px", "margin-top": "20px" },
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.clickAddCond }
                },
                [_vm._v("添加等级 ")]
              ),
              _c(
                "el-button",
                {
                  staticStyle: { width: "100px", "margin-top": "20px" },
                  attrs: { type: "primary", size: "small" },
                  on: { click: _vm.clickAddAllCond }
                },
                [_vm._v("添加所有等级 ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }