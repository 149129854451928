var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: _vm.dialogVisible,
        width: "60%",
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        fullscreen: true,
        "show-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.onClose
      }
    },
    [
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "flex-direction": "column",
            "align-items": "center",
            "background-color": "#333333"
          }
        },
        [
          _vm.row
            ? _c(
                "div",
                {
                  staticStyle: {
                    "overflow-y": "auto",
                    padding: "15px 30px",
                    width: "900px",
                    "background-color": "white"
                  }
                },
                [
                  _c(
                    "el-collapse",
                    {
                      model: {
                        value: _vm.activeName,
                        callback: function($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName"
                      }
                    },
                    [
                      _c(
                        "el-collapse-item",
                        { attrs: { title: "基础信息配置", name: "0" } },
                        [_c("base-config", { attrs: { config: _vm.config } })],
                        1
                      ),
                      _c(
                        "el-collapse-item",
                        { attrs: { title: "会员资格信息配置", name: "1" } },
                        [_c("level-config", { attrs: { config: _vm.config } })],
                        1
                      ),
                      _c(
                        "el-collapse-item",
                        { attrs: { title: "店补等级配置", name: "2" } },
                        [_c("store-config", { attrs: { config: _vm.config } })],
                        1
                      ),
                      _c(
                        "el-collapse-item",
                        { attrs: { title: "会员等级信息配置", name: "3" } },
                        [_c("grade-config", { attrs: { config: _vm.config } })],
                        1
                      ),
                      _c(
                        "el-collapse-item",
                        { attrs: { title: "系统奖金配置", name: "4" } },
                        [_c("bonus-config", { attrs: { config: _vm.config } })],
                        1
                      ),
                      _c(
                        "el-collapse-item",
                        { attrs: { title: "系统管理税配置", name: "5" } },
                        [
                          _c("manage-tax-config", {
                            attrs: { config: _vm.config }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-collapse-item",
                        { attrs: { title: "系统复销税配置", name: "6" } },
                        [
                          _c("resale-tax-config", {
                            attrs: { config: _vm.config }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-row",
                    { staticStyle: { float: "right", "margin-top": "20px" } },
                    [
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              _vm.dialogVisible = false
                            }
                          }
                        },
                        [_vm._v("取 消")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.clickSubmit }
                        },
                        [_vm._v("保存模板")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "danger" },
                          on: { click: _vm.clickCreate }
                        },
                        [_vm._v("生成/更新系统")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }