var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.row ? _vm.row.name + "条件配置" : "",
        visible: _vm.dialogVisible,
        width: "900px",
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "destroy-on-close": true,
        "show-close": true
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.onClose
      }
    },
    [
      _vm.row
        ? _c(
            "div",
            {
              staticStyle: {
                padding: "15px 30px",
                "background-color": "#f6f6f6"
              }
            },
            [
              _c(
                "el-card",
                { staticClass: "row-card", attrs: { shadow: "never" } },
                [
                  _c(
                    "el-form",
                    { ref: "form", attrs: { "label-width": "150px" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "奖励代数" } },
                        [
                          _c("el-input-number", {
                            attrs: {
                              disabled: _vm.conditions.isStore ? true : false,
                              size: "mini",
                              min: 0,
                              max: 100,
                              label: "描述文字"
                            },
                            model: {
                              value: _vm.conditions.layer,
                              callback: function($$v) {
                                _vm.$set(_vm.conditions, "layer", $$v)
                              },
                              expression: "conditions.layer"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "计算类型" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.conditions.bonusMode,
                                callback: function($$v) {
                                  _vm.$set(_vm.conditions, "bonusMode", $$v)
                                },
                                expression: "conditions.bonusMode"
                              }
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("比率")
                              ]),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("金额")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "计算方式" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              attrs: {
                                disabled: _vm.conditions.isStore ? true : false
                              },
                              model: {
                                value: _vm.conditions.computeType,
                                callback: function($$v) {
                                  _vm.$set(_vm.conditions, "computeType", $$v)
                                },
                                expression: "conditions.computeType"
                              }
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("非级差计算")
                              ]),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("级差计算")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "是否是店补奖(选择是只给服务中心奖励)"
                          }
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              on: { change: _vm.onIsStoreChange },
                              model: {
                                value: _vm.conditions.isStore,
                                callback: function($$v) {
                                  _vm.$set(_vm.conditions, "isStore", $$v)
                                },
                                expression: "conditions.isStore"
                              }
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("否")
                              ]),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("是")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "生效的订单类型" } },
                        [
                          _c(
                            "el-checkbox-group",
                            {
                              model: {
                                value: _vm.conditions.orderTypes,
                                callback: function($$v) {
                                  _vm.$set(_vm.conditions, "orderTypes", $$v)
                                },
                                expression: "conditions.orderTypes"
                              }
                            },
                            [
                              _c(
                                "el-checkbox",
                                { attrs: { label: 1, name: "type" } },
                                [_vm._v("注册单")]
                              ),
                              _c(
                                "el-checkbox",
                                { attrs: { label: 2, name: "type" } },
                                [_vm._v("复销单")]
                              ),
                              _c(
                                "el-checkbox",
                                { attrs: { label: 3, name: "type" } },
                                [_vm._v("升级单")]
                              ),
                              _c(
                                "el-checkbox",
                                { attrs: { label: 4, name: "type" } },
                                [_vm._v("激活单")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "影响奖金因素" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "250px" },
                              attrs: {
                                multiple: "",
                                clearable: "",
                                placeholder: "请选择影响的因素"
                              },
                              on: { change: _vm.factorChange },
                              model: {
                                value: _vm.conditions.factor,
                                callback: function($$v) {
                                  _vm.$set(_vm.conditions, "factor", $$v)
                                },
                                expression: "conditions.factor"
                              }
                            },
                            _vm._l(_vm.factorList, function(item) {
                              return _c("el-option", {
                                key: item.tag,
                                attrs: { label: item.name, value: item.tag }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _vm.conditions.factor.length <= 0
                        ? _c(
                            "el-form-item",
                            { attrs: { label: "统一奖金奖励" } },
                            [
                              _c(
                                "el-row",
                                [
                                  _vm.conditions.bonusMode === 0
                                    ? _c("el-input-number", {
                                        attrs: {
                                          size: "mini",
                                          min: 0,
                                          max: 100
                                        },
                                        model: {
                                          value: _vm.conditions.bonusRateOnly,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.conditions,
                                              "bonusRateOnly",
                                              $$v
                                            )
                                          },
                                          expression: "conditions.bonusRateOnly"
                                        }
                                      })
                                    : _c("el-input-number", {
                                        attrs: { size: "mini", min: 0 },
                                        model: {
                                          value: _vm.conditions.bonusRateOnly,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.conditions,
                                              "bonusRateOnly",
                                              $$v
                                            )
                                          },
                                          expression: "conditions.bonusRateOnly"
                                        }
                                      }),
                                  _c(
                                    "span",
                                    { staticStyle: { "margin-left": "10px" } },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.conditions.bonusMode === 0
                                            ? "%"
                                            : "pv"
                                        )
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _c(
                            "el-form-item",
                            { attrs: { label: "奖金影响因素" } },
                            [
                              _c(
                                "el-row",
                                _vm._l(_vm.conditions.factorKeys, function(
                                  item
                                ) {
                                  return _c(
                                    "el-col",
                                    {
                                      attrs: {
                                        span:
                                          24 / _vm.conditions.factorKeys.length
                                      }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            display: "flex",
                                            "flex-direction": "column"
                                          }
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(item.name))
                                          ]),
                                          item.type === "pv"
                                            ? _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "flex-direction": "column"
                                                  }
                                                },
                                                [
                                                  _vm._l(
                                                    item.children,
                                                    function(
                                                      subItem,
                                                      subIndex
                                                    ) {
                                                      return _c(
                                                        "div",
                                                        [
                                                          _c(
                                                            "el-input-number",
                                                            {
                                                              staticStyle: {
                                                                "margin-top":
                                                                  "6px"
                                                              },
                                                              attrs: {
                                                                size: "mini",
                                                                min: 0
                                                              },
                                                              model: {
                                                                value:
                                                                  subItem.pv,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    subItem,
                                                                    "pv",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "subItem.pv"
                                                              }
                                                            }
                                                          ),
                                                          _c(
                                                            "el-button",
                                                            {
                                                              staticStyle: {
                                                                "margin-left":
                                                                  "10px"
                                                              },
                                                              attrs: {
                                                                type: "text",
                                                                size: "mini"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.clickDelFactorPv(
                                                                    item,
                                                                    subIndex
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [_vm._v("删除 ")]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    }
                                                  ),
                                                  _c(
                                                    "el-button",
                                                    {
                                                      staticStyle: {
                                                        "margin-top": "10px",
                                                        width: "100px"
                                                      },
                                                      attrs: {
                                                        size: "mini",
                                                        type: "text"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.clickAddFactorPv(
                                                            item.children
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [_vm._v("添加因素 ")]
                                                  )
                                                ],
                                                2
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "flex-direction": "column"
                                                  }
                                                },
                                                _vm._l(item.children, function(
                                                  subItem
                                                ) {
                                                  return _c(
                                                    "el-checkbox",
                                                    {
                                                      model: {
                                                        value: subItem.selected,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            subItem,
                                                            "selected",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "subItem.selected"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(subItem.name) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                }),
                                                1
                                              )
                                        ]
                                      )
                                    ]
                                  )
                                }),
                                1
                              ),
                              _c(
                                "el-row",
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "danger" },
                                      on: { click: _vm.resetFactorData }
                                    },
                                    [_vm._v("生成奖励配置")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "success" },
                                      on: { click: _vm.reloadFactorData }
                                    },
                                    [_vm._v("更新奖励配置")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                    ],
                    1
                  ),
                  _c(
                    "el-card",
                    { attrs: { shadow: "never" } },
                    [
                      _c(
                        "span",
                        { attrs: { slot: "header" }, slot: "header" },
                        [_vm._v("奖金奖励配置")]
                      ),
                      _c("el-tree", {
                        attrs: {
                          data: _vm.conditions.bonusRateData,
                          "default-expand-all": "",
                          "node-key": "id"
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var node = ref.node
                                var data = ref.data
                                return _c(
                                  "span",
                                  {
                                    staticClass: "custom-tree-node",
                                    staticStyle: { width: "100%" }
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          "(" +
                                            data.factorName +
                                            ") " +
                                            node.label
                                        )
                                      )
                                    ]),
                                    data.children.length === 0
                                      ? _c(
                                          "span",
                                          { staticStyle: { float: "right" } },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "margin-right": "10px"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.conditions.bonusMode ===
                                                      0
                                                      ? "奖金比率"
                                                      : "奖金金额"
                                                  )
                                                )
                                              ]
                                            ),
                                            _vm.conditions.bonusMode === 0
                                              ? _c("el-input-number", {
                                                  attrs: {
                                                    size: "mini",
                                                    min: 0,
                                                    max: 100
                                                  },
                                                  model: {
                                                    value: data.rate,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        data,
                                                        "rate",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "data.rate"
                                                  }
                                                })
                                              : _c("el-input-number", {
                                                  attrs: {
                                                    size: "mini",
                                                    min: 0
                                                  },
                                                  model: {
                                                    value: data.rate,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        data,
                                                        "rate",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "data.rate"
                                                  }
                                                }),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "margin-left": "10px"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.conditions.bonusMode ===
                                                      0
                                                      ? "%"
                                                      : "pv"
                                                  )
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                )
                              }
                            }
                          ],
                          null,
                          false,
                          2885910616
                        )
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              attrs: { size: "mini" },
              on: {
                click: function($event) {
                  _vm.dialogVisible = false
                }
              }
            },
            [_vm._v("取 消")]
          ),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.clickSubmit } },
            [_vm._v(_vm._s("保 存"))]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }