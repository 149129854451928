import "core-js/modules/es.array.map";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.search";
import "regenerator-runtime/runtime";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import tempTable from "@/components/rw/tempTable/index";
import tempSearch from "@/components/rw/tempSearch/index";
import tempForm from "@/components/rw/tempForm/index";
import tempChart from "@/components/rw/tempChart/index";
import { memberLoginLog } from "../../../../api/apis";
export default {
  components: {
    tempTable: tempTable,
    tempSearch: tempSearch,
    tempForm: tempForm,
    tempChart: tempChart
  },
  data: function data() {
    var _this = this;

    return {
      searchModel_1000: {
        "del": false,
        "id": "id_1000",
        "isCollapse": false,
        "operateData": [{
          "icon": "el-icon-search",
          "tag": "search",
          "title": "搜索",
          "type": "primary"
        }, {
          "icon": "el-icon-position",
          "tag": "export",
          "title": "导出",
          "type": "primary"
        }],
        "searchData": [{
          "children": [{
            "field": [],
            "title": "全部",
            "type": "button"
          }, {
            "field": [],
            "title": "今天",
            "type": "button"
          }, {
            "field": [],
            "title": "昨天",
            "type": "button"
          }, {
            "field": [],
            "title": "最近7天",
            "type": "button"
          }, {
            "field": [],
            "title": "本月",
            "type": "button"
          }, {
            "field": [],
            "title": "本年",
            "type": "button"
          }, {
            "field": [],
            "title": "自定义",
            "type": "custom-datetime-range"
          }],
          "id": 0,
          "relation": false,
          "relationId": "",
          "remote": true,
          "remoteType": 1,
          "select": 0,
          "title": "按登录日期",
          "type": 1,
          "remoteFunc": ["modelConditionFunc_0"]
        }, {
          "children": [{
            "field": [{
              "desc": "会员编号",
              "key": "number",
              "value": ""
            }],
            "operator": ["gte", "lte", "gt", "lt", "eq", "ne", "like", "notLike", "in", "between"],
            "placeholder": "请输入",
            "type": "input-complex"
          }],
          "id": 1,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "select": 0,
          "title": "按会员编号",
          "type": 2,
          "remoteFunc": []
        }, {
          "title": "按登录IP",
          "select": 0,
          "type": 2,
          "id": 2,
          "relation": false,
          "relationId": "",
          "remote": false,
          "remoteType": 0,
          "children": [{
            "type": "input-complex",
            "placeholder": "请输入",
            "field": [{
              "key": "ip",
              "value": "",
              "desc": "IP"
            }],
            "operator": ["gte", "lte", "gt", "lt", "eq", "ne", "like", "notLike", "in", "between"]
          }],
          "remoteFunc": []
        }],
        "type": "searchTemplate"
      },
      tableModel_1001: {
        "type": "tableTemplate",
        "id": "id_1001",
        "del": false,
        "tableModel": [{
          "label": "ID",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": "100",
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "id"
        }, {
          "label": "会员编号",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": "120",
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "number"
        }, {
          "label": "IP",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": "160",
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "ip"
        }, {
          "label": "登录时间",
          "show": 1,
          "add": 1,
          "detail": 1,
          "excel": 1,
          "width": "160",
          "type": "text",
          "rowAddField": "",
          "groupId": "",
          "rules": [],
          "prop": "create_time"
        }],
        "tableSwitchProps": {},
        "tableSelectProps": {},
        "tableCascaderProps": {},
        "tableFileProps": {},
        "rowButton": [],
        "stripe": true,
        "border": true,
        "showPagination": true,
        "showSelection": false,
        "showSummary": false,
        "title": "日志",
        "tableName": "t_user_logs",
        "isGroup": false,
        "isTree": false,
        "headerButton": [],
        "tableAutocompleteProps": {},
        "tableRadioProps": {},
        "defaultExpandAll": true,
        "rowKey": "",
        "treeProps": {
          "children": "children",
          "hasChildren": "hasChildren"
        }
      },
      remoteFunc: {
        modelConditionFunc_0: function modelConditionFunc_0(tag, relationData, resolve) {
          //筛选条件数据
          //[[{key:"type",value:"1"}]]
          var data = [];
          data = [[{
            key: "create_time",
            value: []
          }], [{
            key: "create_time",
            value: _this.$g.fun.getDateRange(new Date(), 0, true)
          }], [{
            key: "create_time",
            value: _this.$g.fun.getDateRange(new Date(), 1, true)
          }], [{
            key: "create_time",
            value: _this.$g.fun.getDateRange(new Date(), 7, true)
          }], [{
            key: "create_time",
            value: _this.$g.fun.getDateRange(new Date(), 30, true)
          }], [{
            key: "create_time",
            value: _this.$g.fun.getDateRange(new Date(), 365, true)
          }], [{
            key: "create_time",
            value: []
          }]];
          resolve(tag, data);
        }
      }
    };
  },
  mounted: function mounted() {
    this.table = this.$refs.ref_1001;
    this.search = this.$refs.ref_1000;
  },
  methods: {
    reqTableData: function reqTableData(params, resolve) {
      var where = {};

      if (this.search) {
        where = this.search.parseWhere();
        params.where = where;
      }

      memberLoginLog(params).then(function _callee(res) {
        return regeneratorRuntime.async(function _callee$(_context) {
          while (1) {
            switch (_context.prev = _context.next) {
              case 0:
                resolve({
                  total: res.count,
                  data: res.rows
                });

              case 1:
              case "end":
                return _context.stop();
            }
          }
        });
      });
    },
    reqAdd: function reqAdd(isEdit, params, row, resolve) {//if (isEdit){
      //  params.id = row.id
      //}
      //xxxAdd(params).then(async res=>{
      //  resolve()
      //})
    },
    reqDel: function reqDel(params, resolve) {
      var ids = params.map(function (item) {
        return item.id;
      }); //xxxDel({id:ids}).then(async res=>{
      //  resolve()
      //})
    },
    onClickLink: function onClickLink(prop, row) {//点击列表link类型回调
    },
    onClickRowButton: function onClickRowButton(btnIndex, row) {//点击列表按钮回调
    },
    onClickHeaderButton: function onClickHeaderButton(btnIndex, row) {//点击列表头部按钮回调
    },
    onSummary: function onSummary(param) {//列表尾部合计回调
    },
    onSwitchChange: function onSwitchChange(val, prop, row) {//列表中switch变化事件
    },
    onClickSearchOperate: function onClickSearchOperate(btnIndex, btnData) {
      //条件菜单按钮点击事件
      if (btnData.tag === this.$tempModel.buttonTag.search) {
        this.table.reloadData();
      } else if (btnData.tag === this.$tempModel.buttonTag.export) {
        this.table.exportExcel();
      } else if (btnData.tag === this.$tempModel.buttonTag.import) {
        var header = btnData.header;
        var results = btnData.results;
      }
    },
    onConditionChange: function onConditionChange(tag, index) {
      this.table.reloadData();
    },
    onRowButtonShow: function onRowButtonShow(btnIndex, row) {
      return true;
    }
  }
};