import "core-js/modules/es.array.index-of";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.string.sub";
import "regenerator-runtime/runtime";
import router from '@/router/index';

var _import = require('@/libs/util.import.' + process.env.NODE_ENV);

import user from '@/store/modules/d2admin/modules/user';
import routes from './routes';
import setting from '@/setting';
import store from '@/store/index';
import { menuList } from '@/api/apis';
var permissionMenu = [];

function importComponent(path) {
  try {
    return _import(path);
  } catch (e) {
    return null;
  }
}

function pushRoute(hotRoute) {
  var data = routes[0].children;
  var isExit = false;
  var toPath = hotRoute.action.substring(1, hotRoute.action.length);

  for (var i in data) {
    if (data[i].path === toPath) {
      isExit = true;
      break;
    }
  }

  if (!isExit) {
    var com = importComponent(hotRoute.controller + hotRoute.action);

    if (com === null) {
      return;
    }

    var routerItem = {
      path: hotRoute.action.substring(1, hotRoute.action.length),
      name: hotRoute.module,
      meta: {
        title: hotRoute.menu_name,
        auth: true,
        cache: true
      },
      component: com
    };
    routes[0].children.push(routerItem);
  }
}

function isRouter(obj) {
  if (!isMenu(obj)) {
    return false;
  }

  if (obj.sub && obj.sub.length > 0) {
    var hasMenu = false;

    for (var i in obj.sub) {
      if (obj.sub[i].is_show === 1) {
        hasMenu = true;
        break;
      }
    }

    if (hasMenu) {
      return false;
    }

    return true;
  }

  return true;
}

function isMenu(obj) {
  return obj.is_show;
}

function convertToMenuAndRoute(obj, aryMenu) {
  var userRules = user.state.info.t_system_role.rules;

  if (userRules.indexOf(obj.id) < 0) {
    //没有权限
    return;
  }

  if (!isMenu(obj)) {
    //不是菜单
    return;
  }

  var menu = {};
  menu.title = obj.menu_name;
  menu.path = obj.action;
  menu.icon = obj.icon;
  if (menu.path !== '/setting/systemCreator') aryMenu.push(menu);

  if (isRouter(obj)) {
    //是路由
    pushRoute({
      controller: obj.controller,
      action: obj.action,
      module: obj.module,
      menu_name: obj.menu_name
    });
  } else {
    menu.children = [];

    for (var i in obj.sub) {
      convertToMenuAndRoute(obj.sub[i], menu.children);
    }
  }
}

export default {
  fetchPermissionInfo: function fetchPermissionInfo() {
    var res, i;
    return regeneratorRuntime.async(function fetchPermissionInfo$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            _context.next = 2;
            return regeneratorRuntime.awrap(menuList({}));

          case 2:
            res = _context.sent;

            for (i in res) {
              convertToMenuAndRoute(res[i], permissionMenu);
            }

            console.log('routes', routes);
            router.addRoutes(routes);
            store.commit('d2admin/menu/asideSet', permissionMenu);
            store.commit('d2admin/page/init', [routes[0]]); // 加载上次退出时的多页列表

            store.dispatch('d2admin/page/openedLoad');
            _context.next = 11;
            return regeneratorRuntime.awrap(Promise.resolve());

          case 11:
          case "end":
            return _context.stop();
        }
      }
    });
  }
};